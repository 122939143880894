.header{
	background-color: #0085CA;
    /* background-image: linear-gradient(to right, #00529c , #003C72); */
    padding: 0.75em 1.5em 0.75em 1.5em;
	color:#ffffff;
    position: fixed;
    width:100%;
    z-index: 999999;
}

.header .logo{
    width: 7%;
    float: left;
    margin-top: 3px;
}

.header .version{
    margin-top: 10px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    font-size: 13px;
    font-weight: 300;
}

.header .header-icon{
    cursor: pointer;
    float: right;
    font-size: 20px;
    padding: 4px 10px 5px 0;
    margin-right: 5px;
    color: #ffffff;
    border-radius: 100%;
    width: 40px;
    text-align: center;
    position: relative;
}

.header .header-icon i{
    margin-left:10px;
    font-size: 17px;
}

.header .header-icon.logout-icon{
    margin-right: 0px;
}

.header .header-badge {
    float: right;
    color: #ffffff;
    position: absolute;
    top: 0px;
    font-size: 11px;
    right: 0px;
    font-weight: 500;
    width: 17px;
    height: 17px;
    text-align: center;
    padding: 0;
    line-height: 17px;
}

.header .header-badge.bg-info{
    background-color: #01ace1 !important;
}

.header .user-name {
    float: right;
    margin-top: 10px;
    margin-left: 5px;
    border-left: solid 1px rgba(255,255,255,0.3);
    padding-left: 15px;
}

/*
.header .alarmNotices{
    cursor: pointer;
    float: right;
    font-size: 20px;
    padding: 4px 10px 5px 0;
    margin-right: 20px;
    color: #ffffff;
    background: rgba(255,255,255,0.1);
    border-radius: 100%;
    width: 40px;
    text-align: center;
    position: relative;
}

.header .alarmNotices i{
    margin-left:10px;
    font-size: 17px;
}

.header .teamsSignUp{
    cursor: pointer;
    float: right;
    font-size: 20px;
    padding: 4px 10px 5px 0;
    margin-right: 20px;
    color: #ffffff;
    background: rgba(255,255,255,0.1);
    border-radius: 100%;
    width: 40px;
    text-align: center;
    position: relative;
}

.header .teamsSignUp i{
    margin-left:10px;
    font-size: 17px;
}

.header .user-icon{
    cursor: pointer;
    float: right;
    font-size: 20px;
    padding: 4px 10px 5px 0;
    margin-right: 10px;
    color: #ffffff;
    background: rgba(255,255,255,0.1);
    border-radius: 100%;
    width: 40px;
    text-align: center;
    position: relative;
}

.header .user-icon i{
    margin-left:10px;
    font-size: 17px;
}

.header .logout-icon{
    cursor: pointer;
    float: right;
    font-size: 20px;
    padding: 4px 10px 5px 0;
    color: #ffffff;
    background: rgba(255,255,255,0.1);
    border-radius: 100%;
    width: 40px;
    text-align: center;
    position: relative;
}

.header .logout-icon i{
    margin-left:10px;
    font-size: 17px;
} */

.header .nrOfTeams{
    background: #01ace1;
    font-size: 11px;
    padding: 1px 4px;
    color: #ffffff;
    position: absolute;
    right: -7px;
    bottom: 0px;
}

.header .nrOfAlarms {
    background: #ff0000;
    font-size: 11px;
    padding: 1px 4px;
    color: #ffffff;
    position: absolute;
    right: -7px;
    bottom: 0px;
}

.teamsList{
	position: absolute;
    z-index: 999999;
    background: #ffffff;
    top: -9px;
    border: solid 1px #eee;
    padding: 20px 25px;
	height: 100vh;
	border-top: none;
	right: -300px;
	border-right: none;
	width:270px;
	box-shadow: 0 0 20px rgb(0 0 0 / 10%);
    transition: all 0.5s ease-out;
}

.teamsList.slide-in {
    right: 0;
}

.teamsList_title{
	color:#333333;
	border-bottom: solid 1px #eee;
	padding-bottom: 15px;
	margin-bottom: 0px;
	margin-top: 10px;
}

.teamsList table{
	margin-top:0;
}

.teamsList table tr{
	border-bottom:solid 1px #eee;
}

.teamsList table td{
	height: 0px;
    padding: 15px;
}

.closeTeams {
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 30px;
}

.closeTeams i {
    font-size: 15px;
    color: #333;
}

.latest-alarms{
    width: 250px;
    min-height: 100px;
    position: absolute;
    z-index: 999999;
    background: #ffffff;
    top: -9px;
    border: solid 1px #eee;
    height: 100vh;
    border-top: none;
    right: -300px;
    border-right: none;
    padding: 20px 0;
    box-shadow: 0 0 20px rgb(0 0 0 / 10%);
    transition: all 0.5s ease-out;
}

.latest-alarms.slide-in {
    right: 0;
}

.latest-alarms .alarm {
    padding: 11.5px 15px 11.5px 15px;
}

.latest-alarms a {
    color: #333333;
    text-decoration: none;
}

.latestAlarms_title {
    padding-left: 25px;
    color: #333333;
    border-bottom: solid 1px #eee;
    padding-bottom: 15px;
    margin-bottom: 0px;
    margin-top: 10px;
}

.closeLatestAlarms {
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 30px;
}

.closeLatestAlarms i {
    font-size: 15px;
    color: #333;
}

.btnSidebar{
    font-size: 20px;
    margin-top: 12px;
    display:none;
}

.alarms-viewMore{
    border-top: solid 1px #ccc;
    width: 100%;
    display: block;
    text-align: center;
    position: absolute;
    bottom: 0px;
    padding: 15px 0;
}

.openalarms_alert{
    display:none;
    position: fixed;
    right: 0px;
    z-index: 999999;
    bottom: 0px;
    background: #ff0000;
    padding: 10.5px 20px;
    width: 250px;
    color: #ffffff;
}

.openalarms_alert i{
    font-size: 20px;
    float: left;
    margin-top: 9px;
    margin-right: 15px;
}

.user-name i{
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

@media screen and (min-width:769px) and (max-width:1024px){
    .btnSidebar{
        display:block;
    }
}