.dataTable-top td{
    vertical-align: top;
}

.dataTable-top td{
    padding-top:15px !important;
}

.DataTable_Container {
    margin: 30px 2.5rem 0px 2.5rem;
}

#DataTable{
    margin-bottom:0;
}

#DataTable thead th{
    padding: 20px 20px 20px 10px;
    border-bottom: 1px solid #efefef;
    font-weight: 600;
    color:#000000;
}

#DataTable tr{
    background:#ffffff;
    cursor: pointer;
}

#DataTable tr:first-child td{
    border-top:none;
}

#DataTable td{
    padding:15px;
}

#DataTable{
    margin-bottom:0;
}

#DataTable .openAlarm .pictogram {
    /* display: inline-block;
    padding: 0 0 0 0;
    margin: 10px 10px 10px 10px;
    background: rgb(255,0,0); */
    display: inline-block;
    margin: 10px 15px 10px 10px;
    background: #0085CA;
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px -1px, rgba(0, 0, 0, 0.14) 3px 3px 5px -1px, rgba(0, 0, 0, 0.12) 3px 3px 5px -1px;
    border-radius: 10px;
    padding: 5px;
}

#DataTable tbody td:first-child{
    padding: 0rem;
}


#DataTable td{
    padding:10px;
    color: #000000;
    font-size: 13px;
    height: 45px;
    font-weight: 300;
    vertical-align: middle;
    border-top: 1px solid #efefef;
    border-bottom: none;
}

#DataTable .openAlarm td{
    padding:10px;
    color: #000000;
    font-size: 13px;
    height: 45px;
    font-weight: 300;
    vertical-align: middle;
    border-top: 1px solid #efefef;
}

#DataTable .openAlarm:first-child td{
    border-top:none;
}

.DataTable_Container {
    background: #ffffff;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
    border-radius: 10px;
    padding: 30px;
    margin: 30px 25px;
}

.DataTable_Container thead{
    display:none;
}


.DataTable_Container table{
    margin-bottom:0;
}

.DataTable_Container h2 {
    float: left;
    margin-top: 15px;
    font-size: 14px;
    font-weight: 600;
    padding-left: 20px;
    width: 100%;
    border-bottom: solid 1px #e7eaed;
    padding-bottom: 15px;
    margin-bottom: 0;
}

.DataTable_Container .historyAlarm .pictogram {
    /* display: inline-block;
    padding: 0 0 0 0;
    margin: 10px 10px 10px 10px;
    background: rgb(255,0,0); */
    display: inline-block;
    margin: 10px 15px 10px 10px;
    background: #0085CA;
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px -1px, rgba(0, 0, 0, 0.14) 3px 3px 5px -1px, rgba(0, 0, 0, 0.12) 3px 3px 5px -1px;
    border-radius: 10px;
    padding: 5px;
}

.DataTable_Container .table tbody td:first-child{
    padding: 0rem;
}

.DataTable_Container .historyAlarm td{
    padding:10px;
    color: #000000;
    font-size: 14px;
    height: 45px;
    font-weight: 100;
    vertical-align: middle;
    border-top: 1px solid #efefef;
}

.DataTable_Container .historyAlarm:first-child td{
    border-top:none;
}

.DataTable_Container .dataTables_length{
    padding: 0 0 30px 0;
}

.DataTable_Container .dataTables_length select{
    border: 1px solid #ced4da !important;
    padding: 5px 5px !important;
    margin-right: 5px !important;
    background:#ffffff;
    border-radius: 10px;
}

.DataTable_Container .dataTables_filter{
    padding: 0 0 30px 0;
}

.DataTable_Container .dataTables_filter input{
    border: 1px solid #ced4da !important;
    padding: 5px 5px !important;
    margin-right: 5px !important;
    background:#ffffff;
    border-radius: 10px;
}

.DataTable_Container table.dataTable.no-footer {
    border-bottom: none;
}

.DataTable_Container .dataTables_info{
    padding: 30px 0 0 0;
}

.DataTable_Container .dataTables_paginate{
    padding: 30px 0 0 0;
}

.DataTable_Container .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    background: none !important;
    background-color: #00529c !important;
    border-color: #00529c !important;
    color: #fff !important;
}

.DataTable_Container .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    background: none !important;
    background-color: #eaebec !important;
    color: #000 !important;
}

.showHead thead{
    display: table-header-group;
}

.badge-info{
    margin-right:20px;
}