.contact-icon{
    background: #0085CA;
    color: #ffffff;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    margin-right: 15px;
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px -1px, rgba(0, 0, 0, 0.14) 3px 3px 5px -1px, rgba(0, 0, 0, 0.12) 3px 3px 5px -1px;
}

.bulk{
    display:none;
}

.contact-team{
    display:inline-block;
    background:#f5f5f5;
    margin-right:5px;
    padding: 7px 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px -1px, rgba(0, 0, 0, 0.14) 3px 3px 5px -1px, rgba(0, 0, 0, 0.12) 3px 3px 5px -1px;
}