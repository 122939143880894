.dashboard-editor{
    height: 100vh;
    display: flex;
    flex-direction: row;
    background: #fafdfb;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999999;
}

.dashboard-editor .tileCatalogue{
    width: 250px;
    border-right: solid 1px #e4ecfb;
    position: relative;
}

.dashboard-editor ul{
    padding:0;
    border-bottom: none !important;
}

.dashboard-editor ul li{
    list-style: none;
    cursor: pointer;
    position: relative;
}

.dashboard-editor .tileCatalogue ul li:hover{
    background:#dae4f6;
}

.dashboard-editor .tileCatalogue ul li{
    padding: 15px 20px;
    color:#333;
}

.dashboard-editor ul li i{
    font-size: 20px;
    vertical-align: sub;
    color: #0085CA;
    
    margin-right:10px;
}

.dashboard-editor .content{
    flex: 1 1 auto;
    overflow: auto;
    background-color: #f7faff;
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
}

.dashboard-editor .modal .modal-content{
    background-color: #ffffff;
    color:#333;
}

.dashboard-editor .modal .modal-content p{
    margin-top:20px;
}

.dashboard-editor .modal .modal-header{
    background: #0085CA;
    color: #fff;
}

/* .dashboard-editor .modal .modal-footer{
    padding-bottom: 20px !important;
} */

.dashboard-editor .modal .form-group{
    margin:0;
    margin-bottom:10px;
}

.dashboard-editor .modal .form-group label{
    margin-bottom:5px;
}

.dashboard-editor .modal .btn-add{
    float:none;
    height:auto;
    display:block;
    margin:auto;
}

.dashboard-editor .react-tabs .btn-del-tab{
    font-size: 12px !important;
    vertical-align: middle !important;
    background:#ff0000 !important;
    color: #ffffff !important;
    margin: 0 !important;
    cursor: pointer;
    position: absolute;
    top: -10px;
    right:-10px;
}

.dashboard-editor .modal .modal-body input {
    margin-bottom:20px;
}

.dashboard-editor .modal#add-tab .modal-body {
    padding: 15px 25px 7px 25px;
}

.dashboard-editor .card h2{
    margin: 0px 0px 0 0px;
    font-size: 16px;
}

.dashboard-editor .container-fluid{
    padding: 0;
}

.dashboard-editor .empty-arrow{
    width: 20%;
    transform: rotate(-30deg);
    margin-top: -40px;
    margin-left: -50px;
}

.dashboard-editor .empty-title{
    margin: 0px;
    margin-left: 150px;
    margin-top: -50px;
    font-weight: 400;
}

.dashboard-editor .empty-subtitle{
    margin: 0px;
    margin-left: 150px;
    margin-top: 10px;
    font-size:16px;
}

.dashboard-editor .saveDash{
    position: absolute;
    bottom:0;
    width: 100%;
    background:#dae4f6;
    font-size: 14px;
    text-align: center;
}

.dashboard-editor .modal .modal-footer{
    padding:0;
}