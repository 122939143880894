.content{
    margin-bottom: 0 !important;
}

.device-info{
    position: absolute;
    right: 0px;
    padding: 15px;
    box-shadow: rgb(183 188 209) 6px 16px 31px -18px;
    width: 400px;
    height: 100vh;
    background: rgb(255, 255, 255);
    top: 57px;
}

.trackTrace{
    width: calc(100% - 400px);
    height: calc(100vh - 77px);
}

.filters i{
    font-size:16px;
    color:#00529c;
}

.gm-style .place-card, .gm-style .default-card, .gm-style .directions-card{
    display:none !important;
}

.locations{
    height:calc(100vh - 80px);
    overflow-y: scroll;
}

.device-loc-historie{
    overflow: hidden;
}

.device-loc-historie .loc-day{
    float: right;
    color: #333;
    font-weight: 600;
    font-size: 13px;
}

.device-loc-historie .loc-day i{
    color: #00529c;
    margin-right:5px;
}

.device-loc-historie h3{
    color: #333;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid rgb(221, 221, 221);
    padding-bottom: 10px;
    margin-bottom: 20px;
    
}

.loc-block{
    position: relative;
}

.loc-block .block-border{
    width: 1px;
    height: 100%;
    position: absolute;
    background: #ddd;
    left: 11px;
    z-index: 0;
    top: 10px;

}

.loc-block .loc-date{
    display: inline-block;
    color: #333;
    box-shadow: #ddd 0px 1px 3px 0px;
    border-radius: 10px;
    padding: 5px 10px 5px 25px;
    background: #fff;
    margin-left: 0px;
    position: relative;
    z-index: 0;
}

.loc-block .loc-city{
    display: inline-block;
    color: #333;
    font-weight: bold;
    margin-left: 15px;
    font-size: 13px;
}

.loc-block .loc-label{
    color: #333;
    display: inline-block;
    width: 100%;
    padding: 0 35px;
    margin: 20px 0 20px 0;
}

.loc-block .loc-address{
    color: #333;
    display: inline-block;
    width: 100%;
    padding: 0 35px;
    margin: 5px 0 20px 0;
}

.device-data .loc-icon{
    margin-top: 5px;
    padding-bottom: 5px;
    margin-left: 6px;
    margin-top: 1px;
    color:#333;
    font-weight: bold;
}

.loc-block .loc-icon{
    position: absolute;
    top: 5px;
    z-index: 1;
    padding-bottom: 5px;
    margin-left: 6px;
    margin-top: 1px;
}

.loc-block .loc-icon i, .device-data .loc-icon i{
    color: #00529c;
    font-size: 16px;
    margin-top:1px;
}

.card.battery h3{
    text-align: center;
    margin-bottom: 15px;
}

.card.battery h3 i{
    color: #00529c;
    display: inline-block;
    vertical-align: middle;
}

.card.battery h3 span{
    display: inline-block;
    font-size: 16px;
    vertical-align: middle;
    color: #333;
}

.card.battery .progress{
    background: #f8f8fa;
    height: 40px;
    border: solid 2px #efefef;
    padding: 5px;
    border-radius: 0;
    position: relative;
    overflow: visible;
}

.card.battery .progress .progress-bar{
    /* background-color: #00529c; */
    position: relative;
    overflow: visible;
}

.card.battery .progress.batGreen .progress-bar {
    background-color: green;
}

.card.battery .progress.batOrange .progress-bar, .border-top.batOrange {
    background-color: orange;
}

.card.battery .progress.batRed .progress-bar, .border-top.batRed {
    background-color: red;
}

.batMsg{
    display:none;
}

.batMsg.batOrange{
    background: rgba(255, 165, 0, 0.1);
    display:block;
}

.batMsg.batRed{
    background: rgba(255, 0, 0, 0.1);
    display:block;
}

.location-history-header{
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    margin: 0px 40px 15px 40px;
}

.currentTemp{
    font-size: 20px;
    color: #333;
    width: 100%;
    text-align: center;
    display: block;
    font-weight: 700;
}