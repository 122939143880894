.AlarmDetails {
    background: #ffffff;
    font-size: 14px;
    line-height: 30px;
    color: #333333;
    margin: 40px;
    padding: 20px;
}

.dashboard h1{
    color: #5a5c69;
    font-size: 20px;
    padding: 1.5rem 25px 0 25px;
    font-weight: 400;
}

.card-columns{
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
}

.card{
    display: inline-block !important;
    width: 100%;
    margin-bottom: 30px;
    border: none;
    padding: 25px 20px !important;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
    border-radius: 10px;
}

.grid .card h2 {
  float: left;
  font-size: 14px;
  padding-left: 5px;
  width: 100%;
  padding-bottom: 15px;
  margin: 0px 40px 5px 0px;
  color: #757575;
  font-weight: 400;
}

.masonry .grid-item { width: 50%; }

.alarmStatus {
    float: right;
    text-align: right;
}

.actions{
    margin-top: 20px;
}

.btn-accept {
    background-color: #5cb85c;
    color: #ffffff;
    font-size: 14px;
    margin-right: 5px;
}

.btn-ignore {
    background-color: #ff0000;
    color: #ffffff;
    font-size: 14px;
    margin-right: 5px;
}

.btn-finish{
	background-color:#5cb85c;
	color:#ffffff;
    font-size: 14px;
    margin-right: 5px;
}

.btn-camera{
	background-color:rgb(51, 102, 204);
	color:#ffffff;
    font-size: 14px;
    margin-right: 5px;
}

.alarmcamera {
    display: none;
    background: #ffffff;
    font-size: 14px;
    line-height: 30px;
    color: #333333;
    margin-top: 20px;
    padding: 20px;
    text-align: center;
}

.camTitle{
    float: left;
    margin-top: -3px;
    font-size: 14px;
    font-weight: 600;
    padding-left: 20px;
    width: 100%;
    border-bottom: solid 1px #e7eaed;
    padding-bottom: 15px;
    margin-bottom: 30px;
}

/** Tabs */

#tabs{
	background:none !important;
	border:none;
	font-family: inherit !important;
}

#tabs .ui-tabs-nav{
	background:#ffffff !important;
	border-bottom: 1px solid rgba(0,0,0,.125);
    border-top: none;
    border-left: none;
    border-right: none;
}

#tabs .ui-tabs-nav li.ui-tabs-active{
	margin: 15px 0px 15px 15px !important;
	border:none !important;
	background:#00529c !important;
	border-radius: 0;
}

#tabs .ui-tabs-nav li.ui-tabs-active i{
	color:#ffffff;
	cursor: pointer;
}

#tabs .ui-tabs-nav li.ui-tabs-active a{
	color:#ffffff;
}

#tabs .ui-tabs-nav li{
	margin: 15px 0px 15px 15px !important;
	border:solid 1px #eeeeee !important;
	background:none !important;
}

#tabs .ui-tabs{
	background:transparent !important;
	border:none !important;
}

#tabs li{
    float: left;
    text-decoration: none;
    font-weight: normal;
    font-size: 13px;
}

#tabs li a{
    padding: .5em 1em;
    text-decoration: none;
}

.card-body{
    padding: 10px;
}

.contact i{
    font-size: 60px;
    float: left;
    margin-right: 20px;
}

.alarmnotactive {
    background: #999999;
}

.alarmon{
    background:#5cb85c;
}

.alarmoff{
    background:#ff0000;
}

.alarm-icon {
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    margin: 5px 10px;
}

.btn-del-tab {
    cursor: pointer;
    color: #ff0000;
    padding: 6px;
    border-radius: 100%;
    font-size: 12px;
}

.react-tabs__tab{
    border: solid 1px #eeeeee !important;
    background: #eee !important;
    color: #333;
    font-size: 13px !important;
    margin: 0 !important;
    border-radius: 10px !important;
    padding: 10px 20px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.react-tabs__tab--selected {
    color: #ffffff;
    background: #0085CA !important;
    color: #fff !important;
    border-radius: 0 !important;
    border-radius: 10px !important;
    padding: 10px 20px !important;
}

h2.pending{
    position:absolute;background:#FFA500;color:#ffffff;
}

h2.new{
    position:absolute;background:#FF0000;color:#ffffff;
}

.configuremode, .configuremode.modeOff, .configuremode-btn.modeOff {
    cursor: pointer;
    color: #00529c;
    float: right;
    position: relative;
    z-index: 10;
    font-size: 15px;
    margin-right: 0px;
    border-radius: 100%;
    padding: 15px;
}

.dash_view{
    cursor: pointer;
    color: #00529c;
    float: right;
    position: relative;
    z-index: 10;
    margin-top: 13px;
    font-size: 15px;
    margin-right: 20px;
    border-radius: 100%;
    padding: 15px;
    width: 45px;
    text-align: center;
}

.dash_view:hover{
    background: #f5f5f5;
}

.dash_view_options{
    width: 200px;
    position: absolute;
    right: 10px;
    background: #ffffff;
    top: 110px;
    min-height: 50px;
    z-index: 999999;
    border: solid 1px #eee;
}

.dash_view_options ul{
    margin:0;
    padding:0;
}

.dash_view_options ul li{
    list-style: none;
    color: #000;
    padding: 10px;
    border-bottom: solid 1px #eee;
    width: 100%;
    text-align: left;
    font-size: 12px;
}

.dash_view_options ul li.current{
    color: #00529c;
    font-weight: bold;
}

.configuremode.modeOn, .configuremode-btn.modeOn {
    cursor: pointer;
    background: #00529c;
    color: #ffffff;
    float: right;
    position: relative;
    z-index: 10;
    margin-top: 13px;
    font-size: 15px;
    border-radius: 100%;
    padding: 15px;
}

  .addCardType {
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 20px;
    background: #00529c;
    border-radius: 100%;
    color: #ffffff;
    padding: 14px 16px;
    z-index: 10;
}

.tabs li i, .tabs .card h2 i{
    display:none;
}

.showControls li i, .showControls .card h2 i{
    display:inline-block;
}

.showControls li{
    padding:0 !important;
}

.react-tabs__tab-list{
    border-bottom: none !important;  
    margin: 20px 25px !important;
    padding: 0 !important;
}

.checkboxoption {
    color: #333333;
    border: solid 1px #dddddd;
    padding: 15px;
    margin-bottom: 10px;
}

.checkboxoption table td {
    width: 200px;
}

table{
    width:100%;
}

table td {
    color: #000000;
    font-size: 14px;
    height: 45px;
    font-weight: 300;
}

table td input[type="checkbox"]{
    margin-right:5px;
}

.card .alarms_filters{
    margin-top: 3px;
    position: absolute;
    right: 10px;
    font-size: 13px;
}

.card .alarms_filters .btn-alarms-filter{
    font-size:13px;
}

.card .alarms_filters .current{
    color: #00529c !important;
    font-weight: bold;
}

.card #alarms7days{
    display:none;
}

.form-group{
    margin-bottom:20px;
}

.contact{
    color: #333333;
    padding: 20px 10px;
}

.contact i{
    background: -webkit-linear-gradient(45deg, #00529c, #5f9ad0 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* .legend-day, .legend-week, .legend-month, .legend-year{
    display: none;
    position: absolute;
    background: #ffffff;
    width: 96.6%;
    margin-top: 30px;
    z-index: 10;
    overflow-y: scroll;
    height: 300px;
} */

.loadingScreen{
    position: fixed;
    z-index: 999999;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 1);
  }
  
  .loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #00529c;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 40%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 45%;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }
  
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    background: grey;
    margin-bottom: 30px;
  }

  .dash_view_options span{
    background: #f9f9f9;
    color: #333;
    font-weight: 600;
    display:block;
    padding: 10px;
  }

  .tipsblock{
    cursor: pointer;
  }

  .tipsblock-front, .tipsblock-back{
    display:none;
  }

  .tipsblock-front h3{
    text-align: center;
    color: #212529;
    padding: 60px 0 0 0;
    font-size: 22px;
  }

  .tipsblock-back h3{
    text-align: center;
    color: #212529;
    padding: 60px 0 60px 0;
    font-size: 20px;
  }

  .tipsblock-front span{
    display: block;
    width:100%;
    text-align: center;
    color: #000000;
    padding: 20px 0 50px 0;
    font-size: 14px;
  }

  .tipsblock-front span i{
    color: #00529c;
    margin-right: 10px;
  }

  .tipsblock-front .front-left-top{
    background: #00529c;
    position: absolute;
    top:0;
    left: 0;
    width:150px;
    height: 50px;
  }

  .tipsblock-front .front-left-bottom{
    background: #00529c;
    position: absolute;
    left: 0;
    bottom: 0;
    width:150px;
    height: 50px;
  }

  .tipsblock-front .front-right-top{
    background: #FFA500;
    position: absolute;
    top:0;
    right: 0;
    width:150px;
    height: 50px;
  }

  .tipsblock-front .front-right-bottom{
    background: #FFA500;
    position: absolute;
    bottom:0;
    right: 0;
    width:150px;
    height: 50px;
  }

  .tipsblock-back .top{
    background: #00529c;
    height: 20px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .tipsblock-back .right{
    background: #00529c;
    height: 100%;
    width: 20px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .tipsblock-back .left{
    background: #00529c;
    height: 100%;
    width: 20px;
    position: absolute;
    left: 0;
    top: 0;
  }

  .tipsblock-back .bottom{
    background: #00529c;
    height: 20px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .slider-wrapper {
    margin: 1rem;
    position: relative;
    overflow: hidden;
  }
  
  .slides-container {
    height: calc(100vh - 2rem);
    width: 100%;
    display: flex;
    overflow: scroll;
    scroll-behavior: smooth;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .slide-arrow {
    position: absolute;
    display: flex;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 4rem;
    background-color: white;
    border: none;
    width: 2rem;
    font-size: 3rem;
    padding: 0;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 100ms;
  }
  
  .slide-arrow:hover,
  .slide-arrow:focus {
    opacity: 1;
  }
  
  #slide-arrow-prev {
    left: 0;
    padding-left: 0.25rem;
    border-radius: 0 2rem 2rem 0;
  }
  
  #slide-arrow-next {
    right: 0;
    padding-left: 0.75rem;
    border-radius: 2rem 0 0 2rem;
  }
  
  .slide {
    width: 100%;
    height: 100%;
    flex: 1 0 100%;
  }

  .DataTable_Container label, .dataTables_info, .dataTables_paginate a{
    color:#222 !important;
    font-size: 13px;
    font-weight: 300;
  }
