.sidebar {
    position: fixed;
    background-color: #ffffff;
    width: 255px;
    height: 100%;
    box-shadow: 0 0 20px rgb(0 0 0 / 10%);
    overflow-y: scroll;
    z-index: 999998;
    transition: width 0.25s;
    -ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: thin;  /* Firefox */
    padding-top: 56px;
}

.sidebar .angle{
    float:right;position:relative;
}

.sidebar::-webkit-scrollbar {
    display: none;
}

.sidebar .logo{
	color: #ffffff;
    font-size: 35px;
    display: block;
    text-align: center;
    padding: 20px 0 0 0;
}

.sidebar .logo img{
	margin: 0 10px;
	width: 60%;
}

.sidebar .sidebar_menu{
	padding:0;
	margin:0;
    height: 100%;
}

.sidebar .sidebar_menu li{
	list-style-type: none;
	color:#555;
	padding: 15px 0px;
	cursor: pointer;
}

.sidebar .sidebar_menu li.submenu-item{
	color: #000000;
    font-size: 13px;
    font-weight: 300;
    padding-left: 15px;
    padding-right: 15px;
}

.sidebar .sidebar_menu li i{
	position: relative;
    font-size: 13px;
    line-height: 1;
    margin-right: 1.125rem;
}

.sidebar .sidebar_menu li i.submenu{
	margin-top:5px;
	margin-right:0;
}

.sidebar .sidebar_menu li i.icon{
	color:#0085CA !important;
    width:10px;
}

.sidebar .menu-item-label {
    padding: 0 15px 10px 15px;
    font-size: 12px;
    font-weight: 400;
    color: #757575;
}
.sidebar .submenu-item:hover, .sidebar .active .submenu-item{
    background: #eee;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.sidebar a{
    text-decoration: none;
}

.sidebar .menu-item-label-name {
    display: inline-block;
}

.sidebar .menu-collapse ul {
    padding: 0 !important;
    padding-left: 10px;
}

/* .sidebar .menu-collapse {
    display: none;
}

.sidebar .show {
    display: block !important;
} */

.sidebar .version{
    color: #aaa;
    font-size: 11px;
    margin-top: 10px;
}
.sidebar .sidebar_menu li i.icon
.closeSidebar{
    float:right;
    display: none !important;
    font-size: 20px;
    margin-right: 15px;
    margin-top: 15px;
}

.select_location{
    margin: 20px 0;
}

.select_location #locations{
    width:220px;
    display: block;
    margin: auto;
    border-radius: 10px;
}

@media screen and (min-width:769px) and (max-width:1024px){
    .sidebar{
        display:none;
    }

    .closeSidebar{
        display:block !important;
    }
}