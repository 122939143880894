span.mbaspan{
    color: #333;
    font-weight: 600;
    font-size: 15px;
    margin: 20px 40px;
    display: inline-block;
    width: 100%;
}

.mbamenu{
    text-align: center;
    background: #ffffff;
    padding: 5px;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
}

.mbamenu a{
    list-style-type: none;
    display:inline-block;
    background:#ffffff;
    padding:10px 10px;
    margin:5px;
    color:#333333;
    text-decoration: none;
}

.mbamenu a.active{
    background: #f6f7fb;
}

.mba-btn{
    float: right;
    font-weight: bold;
    background: transparent;
    color: #ffffff;
    border: none;
    margin-top: 10px;
    margin: 10px;
    text-decoration: none;
}

.mba-btn.active{
    background:transparent;
}

.disabledText{
    color: #CCC !important;
}

.italic{
    font-style: italic;
}

.actions i{
    margin: 0 5px;
    font-size:20px;
}

.settings_overview{
    margin: 30px 40px;
}

.settings_overview ul{
    padding:0;
    margin:0;
}

.settings_overview h3{
    color:#333333;
    font-size:16px;
    margin:20px 0;
}

.settings_overview ul li{
    list-style-type: none;
    display: inline-block;
    border:solid 1px #dddddd;
    background:#ffffff;
    padding:20px 20px;
    width:32.7%;
    margin:5px;
    color:#333333;
    border-radius: 10px;
    cursor: pointer;
    font-size: 13px;
}

.settings_overview ul li i{
    margin-right:10px;
    color:#0085CA;
    font-size:16px;
}